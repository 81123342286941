import { tns } from 'tiny-slider/src/tiny-slider'

const sliders = document.querySelectorAll('[data-js-quote-slider]')

sliders.forEach(slider => {
  tns({
    navPosition: 'bottom',
    controls: false,
    container: slider,
    mouseDrag: true,
    lazyload: true
  })
})
